<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav position="start" 
        class=" text-white  pt-4"
        [mode]="sidenav_pantallas=='side' ? 'side' : 'over'" 
        [class]="!expandir?'side':'side-expand'" 
        (mouseleave)="sidenav.close()" 
        (mouseover)="expandir=true">
      
    <div class=" fixed inset-y-0 z-50 flex w-72 flex-col" style="width: 230px!important;">
      <div style="background-image: url('assets/img/backgrounds/login-bg.png')!important; background-repeat: no-repeat; background-size: cover;"
            class="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">

        <div class="flex h-16 shrink-0 items-center">
          <img src="assets/img/images/Logo-BrameOn-Blanco.png" style="width: 400px;" alt="Logo de BrameOn">
        </div>

        <nav class="flex flex-1 flex-col justify-between">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li>
                  <p class="text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal bg-primary hover:cursor-pointer poppins-medium" 
                      style="font-size: 13px!important" 
                      [routerLink]="['recursoshumanos']" 
                      (click)="cambiarTitulo('Recursos Humanos'); sidenav.toggle()"
                      *ngIf="loginService.permisos.includes('RRH_VER')">
                    <mat-icon>person</mat-icon>
                    Recursos Humanos
                  </p>
                  <p class="text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal bg-primary hover:cursor-pointer poppins-medium mt-3" 
                      style="font-size: 13px!important" 
                      [routerLink]="['controlvehicular']"
                      (click)="cambiarTitulo('Control Vehicular'); sidenav.toggle()" 
                      *ngIf="loginService.permisos.includes('COV_VER')">
                    <mat-icon>directions_car</mat-icon>
                    Control Vehicular
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <p class="text-white flex gap-x-3 p-2 text-sm font-normal hover:cursor-pointer poppins-medium justify-end"
                style="font-size: 13px!important"
                *ngIf="loginService.permisos.includes('ADM_VER')">
              <mat-icon [routerLink]="['admin/usuarios']"
                        (click)="sidenav.toggle()" (click)="cambiarTitulo('Administrador')">
                settings
              </mat-icon>
            </p>
          </ul>
        </nav>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="top-bar bg-white">
      <mat-icon class="hover:cursor-pointer"
                (click)=" sidenav.toggle()">
        menu
      </mat-icon>
      <h1 class="titulo-menu poppins-bold">{{ getTitulo() }}</h1>
      <div style="width: 250px; display: flex; flex-direction: row; column-gap: 10px;">
        <div class=" rounded-lg  hover:cursor-pointer usuario-informacion"
              mat-button [matMenuTriggerFor]="menu">
          <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100" 
                *ngIf="!loginService.user.ruta_foto_perfil">
            <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <img class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-50" 
                alt="Foto de perfil del usuario" 
                [src]="loginService.user.ruta_foto_perfil" 
                *ngIf="loginService.user.ruta_foto_perfil">
          <div class="flex flex-col" style="width: auto;" *ngIf="usuario">
            <span class="poppins-medium texto-naranja-primario" 
                  style="font-size: 15px!important;" 
                  aria-hidden="true">
              {{ loginService.user.nombre_usuario }}
            </span>
            <span class="poppins-medium" 
                  style="font-size: 12px!important; color: gray"
                  aria-hidden="true">
              {{ loginService.user.puesto_descripcion }}
            </span>
          </div>
          <mat-icon>expand_more</mat-icon>
        </div>
        <mat-menu #menu="matMenu" style="width: 210px">
          <button mat-menu-item style="width: 210px; font-size: 14px!important;">
            Ayuda
          </button>
          <button mat-menu-item style="width: 210px; font-size: 14px!important;" 
                  (click)="loginService.doLogout()">
            Cerrar sesi&oacute;n
          </button>
        </mat-menu>
      </div>
    </div>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>