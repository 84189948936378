import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { MatDrawerMode } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TituloService } from '../services/titulo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent  implements OnInit{
  mode = new FormControl('over' as MatDrawerMode);
  expandir:boolean = false;
  sidenav_pantallas = 'side';
  hasBackdrop = new FormControl(null as null | boolean);
  position = new FormControl('start' as 'start' | 'end');

  usuario = this.loginService.user;
  constructor(public loginService:LoginService, 
              private router:Router, 
              public dialog: MatDialog) {}

  ngOnInit() {
    this.mostrarSidenav()
    this.usuario = JSON.stringify(this.loginService.user);
    this.router.navigate(['/home/recursoshumanos/']);
    TituloService.setTitulo('Recursos Humanos');
  }

  mostrarSidenav(): boolean {
    if (window.innerWidth < 767) {
      this.sidenav_pantallas = 'over';
    }
    return window.innerWidth > 767;
  }

  getTitulo(): string {
    return TituloService.getTitulo();
  }

  cambiarTitulo(titulo: string): void {
    TituloService.setTitulo(titulo);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.mostrarSidenav();
  }
}
