import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TituloService {

  static titulo: string = '';

  constructor() { }

  static setTitulo(titulo: string): void {
    TituloService.titulo = titulo;
  }

  static getTitulo(): string {
    return TituloService.titulo;
  }
}
